import React, { useMemo, useState } from "react"

import BackgroundImage from "gatsby-background-image"
import PropTypes from "prop-types"
import { config, useSpring, animated } from "react-spring"
import styled from "styled-components"

import Button from "components/common/Button"
import useWindowWidth from "helpers/useWindowWidth"
import { mq } from "styles/theme"

const SIZES = {
  upXS: {
    MAX_TILE_HEIGHT: 380,
    BUTTON_WIDTH: 120,
    BUTTON_X: 120,
    SUBTITLE_HEIGHT: 20,
    SHRINK_TILE_HEIGHT: 90,
  },
  downXS: {
    MAX_TILE_HEIGHT: 380,
    BUTTON_WIDTH: 0,
    BUTTON_X: 230,
    SUBTITLE_HEIGHT: 160,
    SHRINK_TILE_HEIGHT: 150,
  },
  downXXS: {
    MAX_TILE_HEIGHT: 150,
    SHRINK_TILE_HEIGHT: 150,
    BUTTON_WIDTH: 0,
    BUTTON_X: 340,
    SUBTITLE_HEIGHT: 50,
  },
}

const StyledTile = styled(BackgroundImage)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.text.inverted};
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  position: relative;
  background-color: ${({ theme }) => theme.color.black};
  overflow: hidden;
  background-repeat: no-repeat;
  height: ${SIZES.upXS.MAX_TILE_HEIGHT}px;
  max-height: ${SIZES.upXS.MAX_TILE_HEIGHT}px;
  ${({ theme }) => theme.mq.xs} {
    height: ${SIZES.downXS.MAX_TILE_HEIGHT}px;
    max-height: ${SIZES.downXS.MAX_TILE_HEIGHT}px;
  }
  @media (max-width: 620px) {
    height: ${SIZES.downXXS.MAX_TILE_HEIGHT}px;
    max-height: ${SIZES.downXXS.MAX_TILE_HEIGHT}px;
  }
`

const StyledWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  display: grid;
  padding: 2rem 1.5rem;
  grid-gap: 0.5rem;
  align-items: center;
  grid-template-columns: auto ${SIZES.upXS.BUTTON_WIDTH}px;
  will-change: height;

  ${({ theme }) => theme.mq.xs} {
    grid-gap: 3rem;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  h3 {
    font-size: 1.7rem;
    line-height: 1.9rem;
    font-weight: 900;
  }
`

const StyledMore = styled.p`
  font-family: ${({ theme }) => theme.font.azo_sans_web};
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-top: 0.8rem;
  font-weight: 500;
`

const ImplementationElement = ({ title, subtitle, to, buttonLabel, backgroundSrc }) => {
  const windowWidth = useWindowWidth()
  const key = useMemo(() => {
    if (windowWidth <= 620) return "downXXS"
    if (windowWidth <= mq.xs) return "downXS"
    return "upXS"
  }, [windowWidth])
  const SIZE = SIZES[key]
  const [hover, setHover] = useState(false)
  const onEnter = () => setHover(true)
  const onLeave = () => setHover(false)

  const isTo = () => (to ? 0 : SIZE.BUTTON_WIDTH / 2)

  const tileSpring = useSpring({
    config: config.default,
    titleX: hover ? isTo() : SIZE.BUTTON_WIDTH / 2,
    titleMargin: hover ? 0 : SIZE.SUBTITLE_HEIGHT,
    buttonX: hover ? 0 : SIZE.BUTTON_X + 20,
    height: hover ? SIZE.SHRINK_TILE_HEIGHT : SIZE.MAX_TILE_HEIGHT,
    subtitleY: hover ? 0 : SIZE.MAX_TILE_HEIGHT / 1.5,
  })

  return (
    <StyledTile
      aria-label={`${title} - ${subtitle}`}
      fluid={backgroundSrc}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <StyledWrapper as={animated.div} style={{ height: tileSpring.height.interpolate(dy => `${dy}px`) }}>
        <animated.h3
          style={{
            transform: tileSpring.titleX.interpolate(dx => `translate3d(${dx}px,0,0)`),
            marginTop: tileSpring.titleMargin.interpolate(dx => `${dx}px`),
            willChange: "transform, margin-top",
          }}
        >
          {title}
          <animated.div
            style={{
              transform: tileSpring.subtitleY.interpolate(dy => `translate3d(0,${dy}px,0)`),
              willChange: "transform",
            }}
          >
            <StyledMore>{subtitle}</StyledMore>
          </animated.div>
        </animated.h3>
        {to && (
          <animated.div
            style={{
              transform: tileSpring.buttonX.interpolate(dx => `translate3d(${dx}px,0,0)`),
              willChange: "transform",
            }}
          >
            <Button aria-label={`Read more about ${title}`} color="purple" padding="15px 20px" to={to}>
              {buttonLabel}
            </Button>
          </animated.div>
        )}
      </StyledWrapper>
    </StyledTile>
  )
}

ImplementationElement.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  backgroundSrc: PropTypes.shape({}),
  buttonLabel: PropTypes.string,
}

ImplementationElement.defaultProps = {
  backgroundSrc: null,
  buttonLabel: "Read more",
}

export default ImplementationElement
