import React from "react"

import Markdown from "marked-react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Container from "../Container"
import Section from "../Section"

const StyledAboutTxtContent = styled.div`
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
`

const GeneralText = ({ aboutContentTxt }) => (
  <Section padding="6.5rem 0">
    <Container size="m">
      <StyledAboutTxtContent>
        <Markdown>{aboutContentTxt}</Markdown>
      </StyledAboutTxtContent>
      {/* <StyledAboutTxtContent dangerouslySetInnerHTML={{ __html: marked.parse(aboutContentTxt) }} /> */}
    </Container>
  </Section>
)

GeneralText.propTypes = {
  aboutContentTxt: PropTypes.string.isRequired,
}

export default GeneralText
