import React, { memo, useState } from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import styled from "styled-components"

import Button from "components/common/Button"
import Container from "components/common/Container"
import Section from "components/common/Section"
import Text from "components/common/Text"

const StyledContainer = styled(Container)`
  position: relative;
  overflow: hidden;
  padding-top: 7.5rem;
  max-width: 1920px;
`

const StyledSectionTitle = styled.h2`
  font-size: 3.6rem !important;
  font-family: ${({ theme }) => theme.font.fatfrank};
  margin: 0 !important;
  padding-bottom: 2rem;
`

const StyledHeaderLine = styled.div`
  display: grid;
  grid-gap: 1rem;
  justify-items: center;
  padding-bottom: 7.5rem;

  p,
  h2,
  a {
    z-index: 1;
  }
`
const StyledImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);

  ${({ theme }) => theme.mq.xs} {
    grid-template-columns: auto;
    & > div:last-child {
      display: none;
    }
  }
`

const StyledShape = styled.div`
  position: absolute;
  z-index: 0;
  transition: 0.65s ease-in-out;
  transition-delay: ${({ delay }) => delay}s;
  height: ${({ size }) => (Array.isArray(size) ? size[0] : size)}px;
  width: ${({ size }) => (Array.isArray(size) ? size[0] : size)}px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible, fromTop }) => (isVisible ? "translateY(0)" : `translateY(${fromTop ? "-100%" : "100%"})`)};

  top: ${({ top }) => top && top[0]}px;
  left: ${({ left }) => left && left[0]}%;
  right: ${({ right }) => right && right[0]}%;

  ${({ theme }) => theme.mq.s} {
    top: ${({ top }) => top && top[1]}px;
    left: ${({ left }) => left && left[1]}%;
    right: ${({ right }) => right && right[1]}%;
  }

  ${({ theme }) => theme.mq.xs} {
    height: ${({ size }) => (Array.isArray(size) ? size[1] : size)}px;
    width: ${({ size }) => (Array.isArray(size) ? size[1] : size)}px;
    top: ${({ top }) => top && top[2]}px;
    left: ${({ left }) => left && left[2]}%;
    right: ${({ right }) => right && right[2]}%;
    display: ${({ hideOnXS }) => hideOnXS && "none"};
  }
`

const CTASection = ({ data, to }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <Section color="cyan" intersected onIntersectChange={setIsVisible} padding="0">
      <StyledContainer noPadding>
        <StyledShape
          as="img"
          fromTop
          isVisible={isVisible}
          left={[18, 6, 62]}
          loading="lazy"
          size={[180, 140]}
          src="/images/earth.svg"
          top={[-40, -40, -50]}
        />
        <StyledShape
          as="img"
          delay={0.45}
          isVisible={isVisible}
          left={[7, 1, 12]}
          loading="lazy"
          size={[140, 120]}
          src="/images/planet.svg"
          top={[280, 260, 240]}
        />
        <StyledShape
          as="img"
          delay={0.6}
          fromTop
          hideOnXS
          isVisible={isVisible}
          loading="lazy"
          right={[20, 10]}
          size={140}
          src="/images/planet.svg"
          top={[-60]}
        />
        <StyledShape
          as="img"
          delay={0.2}
          hideOnXS
          isVisible={isVisible}
          loading="lazy"
          right={[0, -20]}
          size={380}
          src="/images/circles.svg"
          top={[120]}
        />
        <StyledHeaderLine>
          <Text inverted uppercase>
            {data.title}
          </Text>
          <StyledSectionTitle>{data.description}</StyledSectionTitle>
          <Button aria-label={`Go to ${data.title}`} color="cyan-gradient" fat padding="5px 60px" to={to}>
            GO!
          </Button>
        </StyledHeaderLine>
        {data.images && (
          <StyledImagesWrapper>
            {data.images.map(({ localFile }) => (
              <GatsbyImage
                image={getImage(localFile)}
                imgStyle={{ objectFit: "cover" }}
                style={{ height: "100%", width: "100%" }}
              />
            ))}
          </StyledImagesWrapper>
        )}
      </StyledContainer>
    </Section>
  )
}

CTASection.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  to: PropTypes.string.isRequired,
}

export default memo(CTASection)
