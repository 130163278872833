import React, { useState } from "react"

import { animated, useTrail } from "react-spring"
import styled from "styled-components"

import ImplementationElement from "components/common/ImplementationElement"
import Section from "components/common/Section"
import urls from "config/urls"

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  ${({ theme }) => theme.mq.m} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 620px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const E2EProjects = ({ implementations, section_title }) => {
  const [isVisible, setIsVisible] = useState(false)

  const trail = useTrail(implementations.length, {
    config: { mass: 1, tension: 180, friction: 20 },
    opacity: isVisible ? 1 : 0,
    y: isVisible ? 0 : 400,
  })

  return (
    <Section
      animatedTitle={section_title}
      color="graphite"
      intersected
      onIntersectChange={setIsVisible}
      padding="7rem 0 0 0"
    >
      <StyledGrid>
        {trail.map(({ y, ...rest }, index) => {
          const projectEntry = implementations[index]
          const {
            id,
            realization: { slug },
            title,
            technologies,
            image,
          } = projectEntry
          return (
            <animated.div key={id} style={{ ...rest, transform: y.interpolate(dy => `translate3d(0,${dy}px,0)`) }}>
              <ImplementationElement
                backgroundSrc={image && image.localFile.childImageSharp.fluid}
                subtitle={technologies}
                title={title}
                to={`${urls.portfolioElement.url}${slug}`}
              />
            </animated.div>
          )
        })}
      </StyledGrid>
    </Section>
  )
}

E2EProjects.defaultProps = {}

E2EProjects.propTypes = {}

export default E2EProjects
