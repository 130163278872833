import React from "react"

import styled from "styled-components"

import Button from "components/common/Button"
import Section from "components/common/Section"
import urls from "config/urls"

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mq.xs} {
    flex-direction: column;
  }
`

const StyledSectionTitle = styled.h2`
  font-size: 3.6rem !important;
  font-family: ${({ theme }) => theme.font.fatfrank};
  margin-right: 4rem;

  ${({ theme }) => theme.mq.xs} {
    margin-right: 0;
    margin-bottom: 4rem !important;
  }
`

const ServicesLink = () => (
  <Section color="purple" titleMargin="0">
    <StyledTitleWrapper>
      <StyledSectionTitle>Check our other services</StyledSectionTitle>
      <Button aria-label="Go to services" color="cyan-gradient" fat padding="15px 60px" to={urls.services.url}>
        GO!
      </Button>
    </StyledTitleWrapper>
  </Section>
)

ServicesLink.defaultProps = {}

ServicesLink.propTypes = {}

export default ServicesLink
