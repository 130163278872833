import React from "react"

import styled from "styled-components"

import Arrow from "assets/svg/careers/recruitment/hr_arrow.svg"

const StyledArrow = styled(Arrow)`
  position: absolute;
  display: block;
  height: 100%;
  left: 100%;
  bottom: 0;
  z-index: 1;

  ${({ theme }) => theme.mq.m} {
    transform: scaleX(0.5);
    transform-origin: left bottom;
  }

  ${({ theme }) => theme.mq.s} {
    width: 100%;
    height: unset;

    transform: rotate(90deg) scaleX(0.25);
    left: 0;
    bottom: 1px;
  }
`

const StyledTitle = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.color.text.inverted};
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4rem 0 4rem 7rem;
  background: ${({ color }) => color};

  ${({ theme }) => theme.mq.m} {
    font-size: 1.5rem;
    padding: 4rem 0 4rem 3rem;
  }

  ${({ theme }) => theme.mq.s} {
    position: static;
    background: none;
    font-size: 2rem;
    padding: 0 !important;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      background: ${({ color }) => color};
      height: 3px;
      bottom: 0;
      left: 0;
      z-index: 10;
    }
  }
`

const StyledWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-gap: 2rem;
  grid-template-rows: 160px minmax(70px, auto) auto;

  &:first-child {
    ${StyledTitle} {
      padding: 4rem 0 4rem 1rem;
    }
  }

  &:last-of-type {
    ${StyledTitle} {
      padding: 4rem 4rem 4rem 7rem;
    }
    ${StyledArrow} {
      display: none;
    }
  }

  ${({ theme }) => theme.mq.s} {
    position: relative;
    background: ${({ color }) => color};
    grid-gap: 1rem;
    grid-template-rows: auto;
    padding: 13% 2rem 3% 2rem;

    &:first-child {
      padding-top: 3rem;
    }

    &:last-child {
      padding-bottom: 4rem;
    }
  }
`

const StyledDescription = styled.p`
  color: ${({ theme }) => theme.color.text.inverted};
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0 2rem;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mq.s} {
    max-width: 420px;
    margin: 0 auto;
  }
`

const StyledProportions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 3.5rem;
  padding: 0 2rem;
  justify-content: center;

  ${({ theme }) => theme.mq.s} {
    padding-top: 2rem;
    grid-template-columns: repeat(4, auto);
    grid-gap: 2.5rem;
    grid-template-areas: "area_1 area_2 area_3 area_4";
  }
`

const StyledProportionValue = styled.div`
  height: 2.4rem;
  width: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.8rem;
  font-family: ${({ theme }) => theme.font.fatfrank};
  color: ${({ theme }) => theme.color.text.inverted};

  sub {
    font-size: 0.4em;
    align-self: flex-end;
    font-weight: 900;
  }

  ${({ theme }) => theme.mq.s} {
    font-size: 2.2rem;
    height: 2rem;
    width: 2rem;
    color: ${({ theme }) => theme.color.cyan_300} !important;
  }
`

const StyledStepCircle = styled.div`
  background-color: ${({ color, theme }) => color || theme.color.white_000};
  border-radius: 100%;
  position: relative;
  height: 5.5rem;
  width: 5.5rem;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before,
  &:after {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ color, theme }) => color || theme.color.white_000};
    border-radius: 100%;

    ${({ theme }) => theme.mq.s} {
      background-color: ${({ theme }) => theme.color.white_000};
    }
  }

  &:before {
    z-index: -1;
    opacity: 0.42;
    width: 120%;
    height: 120%;
  }
  &:after {
    z-index: -2;
    opacity: 0.42;
    width: 135%;
    height: 135%;
  }

  &:first-child ${StyledProportionValue} {
    color: ${({ theme }) => theme.color.cyan_300};
  }

  ${({ theme }) => theme.mq.s} {
    height: 4.5rem;
    width: 4.5rem;
    background-color: ${({ theme }) => theme.color.white_000};

    &:nth-child(2) {
      grid-area: area_3;
    }
  }
`

const StyledProportionLabel = styled.div`
  font-size: 1.6rem;
  text-align: center;
  color: ${({ theme }) => theme.color.text.inverted};
  font-family: ${({ theme }) => theme.font.fatfrank};
  display: flex;
  justify-content: center;
  align-items: center;
`

const CollaborationStep = ({ client, jmr, description, title, id, color }) => (
  <StyledWrapper key={id} color={color}>
    <StyledTitle color={color}>
      <h3>{title}</h3>
      <StyledArrow fill={color} />
    </StyledTitle>
    <StyledDescription>{description}</StyledDescription>
    <StyledProportions>
      <StyledStepCircle>
        <StyledProportionValue>
          {client}
          <sub>%</sub>
        </StyledProportionValue>
      </StyledStepCircle>
      <StyledStepCircle color={color}>
        <StyledProportionValue>
          {jmr}
          <sub>%</sub>
        </StyledProportionValue>
      </StyledStepCircle>
      <StyledProportionLabel>CLIENT</StyledProportionLabel>
      <StyledProportionLabel>JMR</StyledProportionLabel>
    </StyledProportions>
  </StyledWrapper>
)

export default CollaborationStep
