import React from "react"

import styled from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"
import Text from "components/common/Text"

const StyledTextWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-bottom: 5rem;
  max-width: ${({ theme }) => theme.container.s}px;

  ${({ theme }) => theme.mq.s} {
    padding-bottom: 3.5rem;
  }
`

const StyledMapWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const GlobalMap = () => (
  <Section animatedTitle="GLOBAL CHARACTER" color="cyan">
    <Container size="l">
      <StyledTextWrapper>
        <Text align="center" fontSize="1.6rem" inverted>
          At JMR, we think big and work across boundaries. Our clients come from the USA, France, China, Italy, Poland,
          Canada, the UK and other countries. Our software implementations are present worldwide.
        </Text>
      </StyledTextWrapper>
      <StyledMapWrapper>
        <img alt="Map of worldwide JMR range" loading="lazy" src="/images/map.svg" />
      </StyledMapWrapper>
    </Container>
  </Section>
)

GlobalMap.defaultProps = {}

GlobalMap.propTypes = {}

export default GlobalMap
