import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import GeneralText from "components/common/GeneralText"
import TransitionWrapper from "components/common/Layout"
import Seo from "components/common/Seo"
import SubpageHeader from "components/common/SubpageHeader"
import CollaborationModel from "components/pages/howWeWork/CollaborationModel"
import E2EProjects from "components/pages/howWeWork/E2EProjects"
import GlobalMap from "components/pages/howWeWork/GlobalMap"
import KeyBenefits from "components/pages/howWeWork/KeyBenefits"
import ServicesLink from "components/pages/howWeWork/ServicesLink"

import CTASection from "../components/common/CTASection"
import urls from "../config/urls"

const query = graphql`
  query HowWeWorkQuery {
    strapiHowWeWork {
      collaboration {
        section_description
        section_title
        collaboration_bullet {
          client
          description
          jmr
          title
          id
        }
      }
      end_to_end_projects {
        implementation_tile {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          realization {
            slug
          }
          id
          technologies
          title
        }
        section_title
      }
      key_benefits {
        section_title
        benefits {
          content
          id
          title
        }
      }
      page_description
      page_title
      mvp_building {
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000, placeholder: TRACED_SVG)
            }
          }
        }
        section_subtitle
        section_title
      }
    }
  }
`

const HowWeWork = ({ location }) => {
  const { strapiHowWeWork } = useStaticQuery(query)
  return (
    <TransitionWrapper location={location}>
      <Seo seoPageName="How we work" />
      <SubpageHeader subpageTitle={strapiHowWeWork.page_title} />
      <GeneralText aboutContentTxt={strapiHowWeWork.page_description} />
      <CollaborationModel data={strapiHowWeWork.collaboration} />
      <ServicesLink />
      <KeyBenefits data={strapiHowWeWork.key_benefits} />
      <CTASection
        data={{
          title: strapiHowWeWork.mvp_building.section_subtitle,
          description: strapiHowWeWork.mvp_building.section_title,
          images: strapiHowWeWork.mvp_building.images,
        }}
        to={urls.mvp.url}
      />
      <GlobalMap />
      <E2EProjects
        implementations={strapiHowWeWork.end_to_end_projects.implementation_tile}
        section_title={strapiHowWeWork.end_to_end_projects.section_title}
      />
    </TransitionWrapper>
  )
}

HowWeWork.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default HowWeWork
