import React from "react"

import styled from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.mq.xs} {
    flex-direction: column;
    justify-items: center;
  }
`

const StyledElement = styled.div`
  font-size: 1.6rem;
  text-align: center;
  max-width: ${({ theme }) => theme.container.xs}px;
  flex: 1;
  margin: 0 15px;

  ${({ theme }) => theme.mq.xs} {
    margin: 0 15px 20px;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }
`

const KeyBenefits = ({ data }) => (
  <Section animatedTitle={data.section_title}>
    <Container size="l">
      <StyledWrapper>
        {data.benefits.map(({ id, title, content }) => (
          <StyledElement key={id}>
            <h3>{title}</h3>
            <p>{content}</p>
          </StyledElement>
        ))}
      </StyledWrapper>
    </Container>
  </Section>
)

export default KeyBenefits
