import React from "react"

import styled, { useTheme } from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"
import Text from "components/common/Text"

import CollaborationStep from "./_components/CollaborationStep"

const StyledDescription = styled(Text)`
  max-width: ${({ theme }) => theme.container.s}px;
  margin: 0 auto;
`
const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ childrenLength }) => `repeat(${childrenLength}, 1fr)`};
  margin-top: 5rem;

  ${({ theme }) => theme.mq.s} {
    grid-template-columns: auto;
  }
`

const colorResolver = (index, color) => {
  switch (index) {
    case 0:
      return color.cyan_500
    case 1:
      return color.cyan_600
    case 2:
      return color.cyan_700
    case 3:
      return color.purple_500
    case 4:
      return color.purple_600
    default:
      return color.purple_700
  }
}

const CollaborationModel = ({ data }) => {
  const theme = useTheme()
  return (
    <Section animatedTitle={data.section_title} color="cyan" titleMargin="3rem">
      <Container>
        <StyledDescription align="center" fontSize="1.6rem" inverted uppercase>
          {data.section_description}
        </StyledDescription>
        <StyledContainer childrenLength={data.collaboration_bullet.length}>
          {data.collaboration_bullet.map((bullet, index) => (
            <CollaborationStep color={colorResolver(index, theme.color)} {...bullet} />
          ))}
        </StyledContainer>
      </Container>
    </Section>
  )
}

export default CollaborationModel
